<template>
  <div class="creationCenter">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">创作中心</div>
      <router-link class="rightBtn" to="/releaseRules">发布规则?</router-link>
    </div>
    <div class="main">
      <div class="publish" @click="show = true">
        <div class="publishIconBox">
          <svg-icon class="publishIcon" iconClass="creationCenter_add"></svg-icon>
        </div>
        <div class="publishText">发布你的作品</div>
      </div>
      <div class="work_manage">
        <router-link tag="div" to="/worksManage" class="subheading">
          作品管理
          <div class="view">
            查看
            <svg-icon class="rightArrow" iconClass="rightArrow"></svg-icon>
          </div>
        </router-link>
        <div class="workData">
          <router-link tag="div" to="/worksManage?t=published" class="dataItem">
            <div class="num">{{ pubData.passWorkCount | watchCount }}</div>
            <div class="type">已发布</div>
          </router-link>
          <router-link tag="div" to="/worksManage?t=underReview" class="dataItem">
            <div class="num">
              {{ pubData.pendingReviewWorkCount | watchCount }}
            </div>
            <div class="type">审核中</div>
          </router-link>
          <router-link tag="div" to="/worksManage?t=notReviewed" class="dataItem">
            <div class="num">{{ pubData.noPassCount | watchCount }}</div>
            <div class="type">未过审</div>
          </router-link>
        </div>
        <div class="paymentRatio">
          <div class="free">免费作品 ：{{ pubData.freeTotalCount | watchCount }}</div>
          <div class="pay">收费作品 ：{{ pubData.notFreeTotalCount | watchCount }}</div>
        </div>
      </div>
      <div class="promote">
        <div class="promoteNum">已有{{ pubData.workCreateCount || 0 }}人 成为软妹社认证大V</div>
        <router-link tag="div" to="/myCertification" class="promoteBtn">立即认证</router-link>
      </div>
      <AdvSwiper :advList="advList" v-if="advList.length" :hasPagination="false" class="adv" />
      <van-swipe class="leaderboardSwiper" :loop="false" :show-indicators="false" :width="285">
        <van-swipe-item v-for="(j, k) in list" :key="k" class="swiperItem">
          <div class="leaderboardCard">
            <div class="top">
              <div class="title">{{ j.typeDesc }}</div>
              <router-link tag="div" :to="`/moreLists?type=${j.type}`" class="more">
                <span>查看更多</span>
                <svg-icon class="pinkRightArrow" iconClass="pinkRightArrow"></svg-icon>
              </router-link>
            </div>
            <div v-for="(item, index) in j.members" :key="index">
              <div class="rankItem" v-if="index < 3">
                <svg-icon class="rankIcon" :iconClass="'top' + (index + 1)"></svg-icon>
                <router-link tag="div" :to="`/userHomePage?uid=${item.id}`" class="rankAvatar" :class="{ isCertifica: item.merchantUser == 1 }">
                  <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="item.merchantUser == 1" />
                  <ImgDecypt class="avatar" round :src="item.avatar" />
                  <div class="certification" v-if="item.superUser == 1">
                    <Vlogo :vid="item.id + j.type" />
                  </div>
                </router-link>
                <div class="userInfo">
                  <div class="userName" :class="{ vipName: checkVip(item) }">
                    {{ item.name }}
                  </div>
                  <div class="value" v-if="j.typeDesc == '周关注榜'">
                    {{ item.value + ' 关注' }}
                  </div>
                  <div class="value" v-else>
                    {{ Number(item.value).toFixed(1) + ' 金币' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="noRank" v-if="!j.members || j.members.length <= 0">快来成为NO.1吧~</div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="sizeBox"></div>
    </div>
    <van-popup class="vantPopup" v-model="show" position="bottom">
      <div class="contentBox">
        <div class="pubBox">
          <router-link tag="div" to="/uploadGraphics" class="pubItem">
            <div class="icon videoBox"></div>
            <div>上传图文</div>
          </router-link>
          <router-link tag="div" to="/uploadVIdeo" class="pubItem">
            <div class="icon imgBox"></div>
            <div>上传视频</div>
          </router-link>
        </div>
        <div class="close" @click="show = false"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import AdvSwiper from '@/components/AdvSwiper';
import { AdType, getAdItem } from '@/utils/getConfig';
import { Swipe, SwipeItem, Popup, Toast } from 'vant';
import { getPublish, getLeaderboard } from '@/api/mine';
export default {
  name: 'CreationCenter',
  components: {
    Vlogo,
    ImgDecypt,
    AdvSwiper,
    [Popup.name]: Popup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      pubData: '', // 发布数据
      list: '', // 周榜列表
      show: false,
      advList: [],
    };
  },
  created() {
    this.advList = getAdItem(AdType.CREATION_CENTER);
    this.getData();
    this.queryLeaderboard();
  },
  methods: {
    async getData() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(getPublish);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code === 200) {
          this.pubData = res.data;
        } else {
          Toast(res.tip || '请求失败');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('请求失败');
      }
    },
    async queryLeaderboard() {
      let res = await this.$Api(getLeaderboard);
      if (res && res.code === 200) {
        this.list = res.data.list;
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip(item) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(item.vipExpireDate).getTime();
      if (item && item.vipLevel > 0 && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.creationCenter {
  height: 100%;
  background-color: #fff;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .publish {
      height: 100px;
      border-radius: 6px;
      background: rgb(246, 246, 246);
      padding: 18px 0 8px;
      margin: 10px 16px 0px;
      box-sizing: border-box;

      .publishIconBox {
        width: 39px;
        margin: 0 auto 4px;

        .publishIcon {
          width: 32px;
          height: 32px;
        }
      }

      .publishText {
        font-size: 16px;
        color: rgb(102, 102, 102);
        text-align: center;
      }
    }
    .work_manage {
      margin: 15px 0px;
      .subheading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 17px;
        font-size: 16px;
        padding: 0 16px;
        box-sizing: border-box;
        .view {
          font-size: 14px;
          color: rgb(153, 153, 153);
          .rightArrow {
            width: 16px;
            height: 16px;
          }
        }
      }

      .workData {
        margin: 13px 0 8px;
        padding: 0 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .dataItem {
          text-align: center;

          .num {
            font-size: 20px;
          }

          .type {
            font-size: 14px;
            text-align: center;
            color: rgb(102, 102, 102);
          }
        }
      }

      .paymentRatio {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 30px;
        height: 42px;
        font-size: 16px;
        color: #000;
        margin: 0px 12px 10px;
        background-color: rgb(246, 246, 246);
        border-radius: 4px;
      }
    }
    .promote {
      height: 49px;
      border-radius: 6px;
      font-size: 14px;
      padding: 0 12px;
      margin: 0 12px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: linear-gradient(rgb(255, 154, 181), rgb(255, 150, 177));
      color: #fff;
      .promoteBtn {
        width: 76px;
        height: 28px;
        line-height: 28px;
        border-radius: 4px;
        color: rgb(255, 103, 143);
        text-align: center;
        background-color: #fff;
      }
    }
    .adv {
      margin: 0 16px 10px;
      border-radius: 10px;
      overflow: hidden;
      height: 111px;
      transform-style: preserve-3d;
      /deep/ .swiper-container {
        height: 126px;
      }
      /deep/ .adv-swiper-slide .van-swipe-item {
        height: 111px;
      }
      /deep/ .animationImg {
        align-items: unset;
      }
      /deep/ img {
        height: 111px;
      }
    }
    .sizeBox {
      height: 28px;
    }
  }

  .vipName {
    color: #ff678f !important;
  }

  .leaderboardSwiper {
    margin: 0 16px;
  }

  .leaderboardCard {
    display: inline-block;
    width: 280px;
    height: 244px;
    border-radius: 6px;
    background: rgb(246, 246, 246);
    padding: 12px;
    margin-right: 12px;
    width: 269px;
    box-sizing: border-box;

    .top {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 17px;

      .title {
        font-size: 16px;
      }

      .more {
        font-size: 12px;
        color: rgb(255, 103, 143);
        display: flex;
        align-items: center;
        .pinkRightArrow {
          height: 18px;
          width: 18px;
        }
      }
    }

    .rankItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .rankIcon {
        width: 32px;
        height: 26px;
      }

      .rankAvatar {
        width: 50px;
        height: 50px;
        margin: 0 10px 0 20px;
        position: relative;
        .avatar {
          width: 50px;
          height: 50px;
        }
        .certification {
          width: 15px;
          height: 15px;
          position: absolute;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }

        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }
      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }
    }

    .userInfo {
      font-size: 16px;
      .userName {
        width: 136px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .value {
        width: fit-content;
        padding: 5px 0;
        color: rgb(255, 135, 42);
        // background-image: linear-gradient(to right, rgb(255, 135, 42), rgb(255, 181, 124));
        border-radius: 6px;
        font-size: 12px;
      }
    }

    .noRank {
      height: 140px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 420px) {
    .leaderboardCard {
      width: 250px;
      .userInfo {
        .userName {
          width: 121px;
        }
      }
    }
  }

  .vantPopup {
    background: #00000000;

    .contentBox {
      height: 231px;
      width: 100%;
      padding: 28px 88px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      background: url('./../../../assets/png/uploadBg.png') no-repeat;
      background-size: 100% 100%;
      .pubBox {
        display: flex;
        justify-content: space-between;
        .pubItem {
          text-align: center;
          font-size: 16px;
          .icon {
            height: 54px;
            width: 54px;
            border-radius: 50%;
            margin: 0 auto 8px;
          }
          .videoBox {
            background-image: url('../../../assets/png/photoAlbum.png');
            background-size: 100% 100%;
          }

          .imgBox {
            background-image: url('../../../assets/png/videoplay.png');
            background-size: 100% 100%;
          }
        }
      }
      .close {
        height: 36px;
        width: 36px;
        margin: 23px auto;
        background-image: url('../../../assets/png/close4.png');
        background-size: 100% 100%;
      }
    }
  }
}
</style>
